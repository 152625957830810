import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { IoIosCall } from "react-icons/io";
import Header, {DesktopNavLinks, LogoLink, NavLink, NavLinks, NavToggle} from "components/headers/light.js";
import backgroundImage from "../../images/background.jpg";
import {PrimaryButton} from "../misc/Buttons";
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex flex-col items-center`;
const LeftColumn = tw.div`flex flex-col items-center lg:block mb-10`;

const Heading = styled.h1`
  ${tw`text-3xl text-white text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black leading-none mt-20`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;styled("select")

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${backgroundImage});
`;

export default () => {

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#diensten">
        Diensten
      </NavLink>
      <NavLink href="#expertise">
        Expertise
      </NavLink>
      <NavLink href="#ervaringen">
        Ervaringen
      </NavLink>
      <NavLink href="#faq">
        Vragen
      </NavLink>
      <NavLink href="#contact">
        Contact
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <NavLink tw={"border-none"} href="/offerte-aanvragen">
        <PrimaryButton key={2}>
          Offerte aanvragen
        </PrimaryButton>
      </NavLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <span>Welkom bij</span>
              <br />
              <SlantedBackground> Loodgieter Holland</SlantedBackground>
            </Heading>
          </LeftColumn>
          <a href="tel:0850602457">
            <PrimaryButton tw={"justify-center flex"}>
              <IoIosCall tw={"mr-2 mt-1"}/>
              Bel direct voor hulp!
            </PrimaryButton>
          </a>
        </TwoColumn>

      </HeroContainer>
    </Container>
  );
};
