import React, {useEffect} from "react";
import GlobalStyles from 'styles/GlobalStyles';
//import { css } from "styled-components/macro"; //eslint-disable-line
import {BrowserRouter as Router, Routes, Route, useLocation} from "react-router-dom";
import MainLandingPage from "demos/AgencyLandingPage.js";
import ContactUs from "pages/ContactUs.js";

function CanonicalLinkUpdater() {
    const location = useLocation();

    useEffect(() => {
        const canonicalLink = document.createElement("link");
        canonicalLink.rel = "canonical";

        // Set the canonical URL based on the current location
        canonicalLink.href = `https://loodgieterholland.com${location.pathname}`;

        // Find and update existing canonical tag if it exists
        const existingCanonical = document.head.querySelector('link[rel="canonical"]');
        if (existingCanonical) {
            existingCanonical.href = canonicalLink.href;
        } else {
            document.head.appendChild(canonicalLink);
        }

        // Clean up: Remove the canonical tag when the component unmounts
        return () => {
            const toRemove = document.head.querySelector('link[rel="canonical"]');
            if (toRemove) {
                document.head.removeChild(toRemove);
            }
        };
    }, [location.pathname]);

    return null; // Since this component is for side effects only
}

export default function App() {
    return (
        <>
            <GlobalStyles />
            <Router>
                <CanonicalLinkUpdater />
                <Routes>
                    <Route path="/" element={<MainLandingPage />} />
                    <Route path="/offerte-aanvragen" element={<ContactUs />} />
                </Routes>
            </Router>
        </>
    );
}
