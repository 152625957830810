import AppointmentForm from "./AppointmentForm";

export default () => {
    return (
        <div className="w-full max-w-sm my-10">
            <div>
                <AppointmentForm />
            </div>
        </div>
    );
};
