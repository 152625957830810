import React, {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import css from "styled-components/macro"; //eslint-disable-next-line
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import {PrimaryButton} from "components/misc/Buttons.js";
import cvKetel from "images/cvketel.jpg";
import dakDekken from "images/dakdekken.jpg";
import wcontstoppen from "images/wcontstoppen.jpg";
import riool from "images/rioolinspectie.jpg";
import verstopping from "images/verstopping.jpg";
import lekkage from "images/lekkageoplossen.jpg";
import leidingleggen from "images/leidingleggen.jpg";

import SupportIconImage from "../../images/support-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
const HeadingText = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full xl:mx-0 mx-8 lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 focus:text-primary-500`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Image = styled.img(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat h-full bg-center mx-auto my-5`,

  // Media query for screens larger than a certain width (e.g., 768px)
  tw`md:(max-w-xs my-0) lg:(max-w-sm my-0) xl:(max-w-md my-0)`
]);

const ImageColumn = tw(Column)`md:w-11/12 flex-shrink-0 h-full md:h-auto relative`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      title: "CV Ketels",
      description: "Wij bieden expertise in installatie, onderhoud en reparatie van CV-ketels voor een optimale werking van jouw verwarmingssysteem.",
      fullDescription: "Expertise in Installatie, Onderhoud en Reparatie: Onze gespecialiseerde technici bieden uitgebreide expertise in het installeren, onderhouden en repareren van CV-ketels. We streven naar een optimaal functionerend verwarmingssysteem, waarbij we zowel efficiency als comfort waarborgen. Of het nu gaat om een nieuwe installatie, regulier onderhoud of reparaties, wij staan paraat om jouw CV-ketel in topconditie te houden.",
      imageSource: cvKetel,
    },
    {
      title: "Dakdekken",
      description: "Onze dakdekkers zorgen voor professionele dakreparaties, renovaties en onderhoud om jouw dak in optimale staat te houden.",
      fullDescription: "Professionele Dakreparaties, Renovaties en Onderhoud: Ons toegewijde team van dakdekkers biedt professionele diensten voor reparaties, renovaties en het onderhoud van daken. Of het nu gaat om het herstellen van lekkages, vernieuwen van dakbedekking of het uitvoeren van periodiek onderhoud, wij streven ernaar om jouw dak in optimale staat te houden, bescherming te bieden en duurzaamheid te waarborgen.",
      imageSource: dakDekken,
    },
    {
      title: "WC Ontstoppen",
      description: "Laat verstopte wc's probleemloos ontstoppen door onze ervaren loodgieters voor een optimaal functionerend sanitairsysteem.",
      fullDescription: "Efficiënte Oplossingen voor Verstopte WC's: Onze ervaren loodgieters lossen verstoppingsproblemen van toiletten snel en effectief op. We begrijpen de urgentie van een goed functionerend sanitairsysteem en zorgen ervoor dat verstopte toiletten zonder gedoe worden ontstopt, met minimale verstoring van jouw routine.",
      imageSource: wcontstoppen,
    },
    {
      title: "Rioolinspectie",
      description: "Met geavanceerde technologieën voeren we grondige rioolinspecties uit om problemen op te sporen en effectieve oplossingen te bieden.",
      fullDescription: "Grondige Inspectie voor Effectieve Oplossingen: We gebruiken geavanceerde technologieën voor uitgebreide rioolinspecties. Door grondige inspecties kunnen we problemen vroegtijdig detecteren, nauwkeurige analyses uitvoeren en op basis daarvan effectieve oplossingen bieden om de functionaliteit van het rioolsysteem te herstellen.",
      imageSource: riool,
    },
    {
      title: "Verstopping",
      description: "Wij pakken diverse verstoppingsproblemen aan, van afvoeren tot leidingen, om een vlotte doorstroming te herstellen.",
      fullDescription: "Diverse Probleemoplossingen voor een Soepele Doorstroming: Van verstopte afvoeren tot problemen met leidingen, wij pakken diverse verstoppingsproblemen aan om een optimale doorstroming te herstellen. Onze aanpak is gericht op het effectief oplossen van specifieke verstoppingsproblemen, waardoor een soepele werking van jouw sanitairsysteem wordt gegarandeerd.",
      imageSource: verstopping,
    },
    {
      title: "Lekkage Oplossen",
      description: "Onze experts traceren en herstellen lekkages snel en efficiënt om verdere schade aan je woning te voorkomen.",
      fullDescription: "Snel en Efficiënt Detecteren en Herstellen: Onze experts zijn bedreven in het detecteren en efficiënt herstellen van lekkages. Door snelle detectie en nauwkeurige reparaties voorkomen we verdere schade aan jouw woning, wat resulteert in een duurzame oplossing en gemoedsrust.",
      imageSource: lekkage,
    },
    {
      title: "Leiding Leggen",
      description: "Van nieuwe leidingen tot vervanging, wij verzorgen deskundig leidingwerk om een betrouwbaar sanitairsysteem te garanderen.",
      fullDescription: "Deskundig Leggen en Vervangen van Leidingen: Van het leggen van nieuwe leidingen tot het vervangen van bestaande, wij bieden deskundig leidingwerk om de betrouwbaarheid van jouw sanitairsysteem te garanderen. Onze focus ligt op nauwkeurige installatie en vervanging, met oog voor duurzaamheid en functionaliteit.",
      imageSource: leidingleggen,
    },
  ];
  const ContentDetails = ({ title, description, imageSource }) => (
      <div>
        <HeadingText>{title}</HeadingText>
        <Description tw="text-base">{description}</Description>
        <ImageColumn>
          <Image src={imageSource} />
        </ImageColumn>
      </div>
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null);

  const toggleModal = (index) => {
    setSelectedCard(index);
    setModalIsOpen(!modalIsOpen);
  };

  return (
      <>
    <Container>
      <ThreeColumnContainer>
        <Heading id={"diensten"}>Onze <span tw="text-primary-500">professionele</span> diensten</Heading>
        <Description>
          We bieden een scala aan professionele diensten, maar staat jouw specifieke klus er niet tussen? Geen zorgen, neem gerust contact met ons op.
          We zijn er om verder te helpen en te voorzien in diverse behoeften die mogelijk niet vermeld staan. Jouw tevredenheid is onze prioriteit.
        </Description>
        {
          cards.map((card, i) => (
              <Column key={i}>
                <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
                  <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
                  <PrimaryButton onClick={() => toggleModal(i)} tw={"mt-5 mr-0!"}>Meer Info</PrimaryButton>
                </Card>
              </Column>
          ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
      <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content">
          {
            selectedCard !== null && cards[selectedCard] && (
              <ContentDetails
                  title={cards[selectedCard].title}
                  description={cards[selectedCard].fullDescription}
                  imageSource={cards[selectedCard].imageSource}
              />
            )
          }
        </div>
      </StyledModal>
    </Container>
      </>
  );
};
