import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullFormAndDatePicker.js";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, {DesktopNavLinks, LogoLink, NavLink, NavLinks, NavToggle} from "components/headers/light.js";
import {PrimaryButton} from "../components/misc/Buttons";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export default () => {
    const navLinks = [
        <NavLinks key={1}>
            <NavLink href="/#diensten">
                Diensten
            </NavLink>
            <NavLink href="/#expertise">
                Expertise
            </NavLink>
            <NavLink href="/#ervaringen">
                Ervaringen
            </NavLink>
            <NavLink href="/#faq">
                Vragen
            </NavLink>
            <NavLink href="/#contact">
                Contact
            </NavLink>
        </NavLinks>,
        <NavLinks key={2}>
            <NavLink tw={"border-none"} href="#">
                <PrimaryButton key={2}>
                    Offerte aanvragen
                </PrimaryButton>
            </NavLink>
        </NavLinks>
    ];
  return (
      <>
          <AnimationRevealPage disabled={false}>
              <StyledHeader links={navLinks} />
              <ContactUsForm />
              <Footer />
          </AnimationRevealPage>
      </>
  );
};
